// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';

import api from '../api';

class Header extends Component {

    render = () => {
        return (<div className="header">
            <div className="title">
                <span style={{ color: 'rgb(20,77,118)' }}>MD</span>
                <span style={{ color: 'rgb(240,140,0)' }}>X</span>
            </div>
            <div className="subtitle">
                Markdown Blog Editor
            </div>

            {this.props.user && <div className="menu">
                <NavLink
                    exact
                    to="/"
                    activeClassName="menuActive"
                >My Posts</NavLink>
                <NavLink
                    exact
                    to="/new"
                    activeClassName="menuActive"
                >New Post</NavLink>
                <div className="menuLink" onClick={async () => {
                    await api.post('/self/logout');
                    this.props.history.push("/login");
                }}>Logout</div>
            </div>}
        </div>);
    }

};

export default withRouter(Header);