// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';

import copy from 'copy-to-clipboard';

import { v1 as uuidv1 } from 'uuid';

import SimpleMDE from 'react-simplemde-editor';
import '../styles/easymde.css';

import api from '../api';

import '../styles/editor.css';
import '../styles/prism.css';

import Prism from 'prismjs';
import "prismjs/components/prism-python";
import "prismjs/components/prism-http";
import "prismjs/components/prism-bash";
import "prismjs/components/prism-json";

const imageUrl = (path) => "https://aptus-blog-data.s3-us-west-1.amazonaws.com" + path;

export default class Editor extends Component {

    state = {
        _id: null,
        title: "Loading...",
        content: "Loading...",
        uploads: [],
        save: "All changes saved",
        covert: "_",
        keywords: "",
        description: ""
    }

    filePath = ""

    saveTimeout = null

    componentDidMount = async () => {
        await this.sync(this.props.match.params.post, true);
    }

    componentWillReceiveProps = async (next) => {
        if (next.match.params.postId !== this.props.match.params.postId)
            await this.sync(next.match.params.postId, true);
    }

    upload = async (e) => {

        let formData = new FormData();

        if (!this.filePath) {
            this.filePath = "uploads/" + uuidv1() + "-" + e.target.files[0].name;
            formData.append('post', this.state._id);
        }

        formData.append('path', this.filePath);
        formData.append('image', e.target.files[0]);
        
        await api.post('/post/upload', formData);

        await this.sync(this.state._id, true);

    }

    delete = async () => {

        await api.post('/post/' + this.state._id + '/remove');

        this.props.history.push('/');

    }

    sync = async (postId, load=false) => {

        await this.setState({ save: "Saving..." });

        const post = await api.post('/post/' + postId + '/sync', load ? {} : {
            ...this.state,
            save: undefined,
            covert: undefined
        });

        if (!post)
            return;

        if (load)
            await this.setState({
                ...post,
                save: post.published ? "Changes Published" : "publish"
            });

        else
            await this.setState({
                save: post.published ? "Changes Published" : "publish"
            });

        // Highlight
        Prism.highlightAll();
    }

    changed = async () => {
        await this.setState({ save: "Tracked changes" });
        if (this.saveTimeout)
            clearTimeout(this.saveTimeout);
        
        this.saveTimeout = setTimeout(async () => await this.sync(this.state._id), 1000);
    }

    render = () => {
        return (<div className="page">
            
            <div className="saving">{this.state.save === "publish" ? <>Saved. <span onClick={async () => {
                await this.setState({ published: true });
                await this.sync(this.state._id);
            }} className="publish">Publish?</span></> : this.state.save}</div>

            <div className="banner" style={{ backgroundImage: "url('" + imageUrl("/cover/" + this.state._id + ".jpg") + "?t=" + this.state.covert + "')" }}>
                <div className="changeBanner" onClick={() => {
                    this.filePath = "cover/" + this.state._id + ".jpg";
                    this.refs.imageSelector.click();
                }}>Upload New Banner</div>
            </div>

            <input
                className="titleEdit"
                type="text"
                name="title"
                value={this.state.title}
                onChange={async (e) => {
                    await this.setState({
                        title: e.target.value
                    });
                    await this.changed();
                }}
            />

            <div className="editor">
                <SimpleMDE
                    value={this.state.content}
                    onChange={async (content) => {
                        await this.setState({ content });
                        await this.changed();
                    }}
                />
            </div>

            <div className="uploads">
                <h2>Uploads</h2>
                <div className="button" onClick={() => {
                    this.filePath = null;
                    this.refs.imageSelector.click();
                }}>Upload Image</div>
                {this.state.uploads.map((u,idx) => <img
                    key={idx}
                    src={imageUrl("/uploads/" + u)}
                    title="Click to copy code"
                    alt={u}
                    onClick={() => copy("![](" + imageUrl("/uploads/" + u) + ")")}
                />)}
            </div>
            <div style={{ clear: 'both' }} />
            
            <label>Keywords</label>
            <textarea
                value={this.state.keywords}
                onChange={async (e) => {
                    await this.setState({ keywords: e.target.value });
                    await this.changed();
                }}
            />
            <div style={{ clear: 'both' }} />

            <label>Description</label>
            <textarea
                value={this.state.description}
                onChange={async (e) => {
                    await this.setState({ description: e.target.value });
                    await this.changed();
                }}
            />
            <div style={{ clear: 'both' }} />

            <div className="button deleteButton" title="Double-click to delete" onDoubleClick={() => this.delete()}>Delete Post Permanently</div>

            <input type="file" id="file" ref="imageSelector" style={{ display: 'none' }} onChange={this.upload.bind(this)} />

        </div>);
    }

};