// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import HttpsRedirect from 'react-https-redirect';

import Header from './components/Header';
import Posts from './components/Posts';
import Login from './components/Login';

import api from './api';

import './styles/layout.css';
import './styles/style.css';
import './styles/form.css';
import Editor from './components/Editor';
import New from './components/New';

class App extends Component {

	state = {
		user: null
	}

	componentDidMount = async () => {

		if (window.location.pathname === "/login")
			return;

		// Get user data
		const whoami = await api.get('/self/ident');

		if (!whoami)
			window.location = "/login";

		this.setState({
			user: whoami.user
		});

	}

	render = () => {
		return (
			<HttpsRedirect>
				<Router>
					
					<Header user={this.state.user} />

					<Route exact path="/login" render={(props) => <Login
						{...props}
						updateUser={async user => await this.setState({ user })}
					/>} />

					<Route exact path="/" render={(props) => <Posts {...props} />} />

					<Route exact path="/edit/:post" render={(props) => <Editor {...props} />} />
					
					<Route exact path="/new" render={(props) => <New {...props} />} />

				</Router>
			</HttpsRedirect>
		)
	}

}

export default App;
