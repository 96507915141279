// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';

import api from '../api';

export default class Login extends Component {

    state = {
        email: "",
        password: "",
        processing: false
    }

    onLogin = async () => {
        await this.setState({ processing: true });

        const res = await api.post('/self/login', {
            email: this.state.email,
            password: this.state.password
        });

        console.log(res);

        await this.setState({ processing: false });

        if (!res)
            return;

        await this.props.updateUser(res);

        window.location = '/';
    }

    render = () => {
        return (<div className="page">
            
            <div className="login">

                <h1>Author Login</h1>

                <input
                    type="text"
                    name="email"
                    placeholder="Email address"
                    value={this.state.email}
                    onChange={(e) => this.setState({ email: e.target.value })}
                />

                <input
                    type="password"
                    name="password"
                    placeholder="Password"
                    value={this.state.password}
                    onChange={(e) => this.setState({ password: e.target.value })}
                    onKeyDown={(e) => e.key === "Enter" ? this.onLogin() : undefined}
                />

                {!this.state.processing && <div
                    className="button"
                    onClick={this.onLogin}
                >Login</div>}

            </div>

        </div>);
    }

};