// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import axios from 'axios';

const apiUrl = process.env.REACT_APP_LOCAL === "true" ? "http://localhost:8888" : "https://blog.aptusai.com";

axios.defaults.withCredentials = true;

// Error handling and authorization check
const handleError = async (request, skipAuth) => {

    try {
        const res = await request();
        return res.data;
    }

    catch (error) {

        if (error.response)
            alert(error.response.data);

        if (error.response && error.response.status === 403 && !skipAuth) { // Unauthorized
            if (window.location.pathname !== "/login")
			    window.location = '/login';
            return;
        }

        if (error.response && (error.response.status === 400 || error.response.status === 500)) {
            console.log(error.data);
            return;
        }
    }

};


export default {

    // Get public image url
    image: (path) => "https://aptus-blog-data.s3-us-west-1.amazonaws.com" + path,

    // Get request
    get: async (endpoint, data={}, skipAuth=false, config={}) => {

        return await handleError(async () => await axios.get(apiUrl + endpoint, data, config), skipAuth);

    },

    // Post request
    post: async (endpoint, data={}, skipAuth=false, config={}) => {

        return await handleError(async () => await axios.post(apiUrl + endpoint, data, config), skipAuth);

    },

    // Download
    download: async (endpoint, skipAuth=false) => {

        return await handleError(async () => await axios({
            method: 'get',
            url: apiUrl + endpoint,
            responseType: 'blob'
        }), skipAuth);

    },

    // Open file in window
    open: (endpoint) => {
        window.open(apiUrl + endpoint);
    }

}