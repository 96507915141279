// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';

import api from '../api';

import '../styles/posts.css';

export default class Posts extends Component {

    state = {
        posts: []
    }

    componentDidMount = async () => {
        const posts = await api.get('/self/list');

        if (posts)
            await this.setState({ posts: posts.posts.reverse() });
    }

    render = () => {
        return (<div className="page">
            
            <h1>My Blog Posts</h1>

            {this.state.posts.map((p,idx) => <div
                key={idx}
                className="postsItem"
                onClick={() => this.props.history.push('/edit/' + p.post._id)}
            >
            
                <div
                    className="postsItemCover"
                    style={{ backgroundImage: "url('" + api.image("/cover/" + p.post._id + ".jpg") + "')" }}
                />

                <div className="postsItemDetails">
                    <h1>{p.post.title}</h1>
                    <h2>Posted on {new Date(p.post.date).toDateString()}</h2>
                    <h2>{p.blog.name + (p.post.published ? " (Published)" : " (Draft)")}</h2>
                </div>

            </div>)}

        </div>);
    }

};