// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';

import api from '../api';

export default class New extends Component {

    state = {
        title: ""
    }

    newPost = async () => {
        if (!this.state.title || this.state.title.length < 40)
            return alert("Title is not long enough!");

        const post = await api.post('/post/create', {
            title: this.state.title,
            content: ""
        });

        this.props.history.push('/edit/' + post._id);
    }

    render = () => {
        return (<div className="page">
            
            <h1>Create New Post</h1>

            <label>Post Title</label>
            <input
                type="text"
                value={this.state.title}
                onChange={(e) => this.setState({ title: e.target.value })}
                style={{
                    width: 'calc(100% - 180px)'
                }}
            />

            <div className="button createButton" onClick={() => this.newPost()}>New Post</div>

        </div>);
    }

};